import React from "react"
import Layout from "../../components/layout"
import background from "../../Figures/Site_Header_Background.png"
import { Link } from "gatsby"
import pageLayout from "../pages.module.css"
import ProjectOverview from "../../components/ProjectOverviews/PRO001Overview.js"

export default function CellLinking() {
  return (
    <div>
    <div>
    <Layout>
    </Layout>
    <div style={{backgroundImage: "url(" + background + ")", backgroundSize: "cover", minHeight: "200px"}} >
        <div className={pageLayout.text}>
      <h1 style={{color: "white"}}>PRO001 - Rotary cellphone</h1>
      <br />
      <h2 style={{color: "white"}}>Linking to the cellphone module</h2>
        </div>
      </div>
      </div>
      <div>
      <div className={pageLayout.text}>
        <h2>Intro</h2>
        <p>The A6 SIM module that I bought is <a href="https://www.grandado.com/products/gprs-pro-seriele-a6-gprs-gsm-module-core-diy-developemnt-board-vervangen-sim900?variant=6366958420000&currency=EUR&gclid=Cj0KCQjwpZT5BRCdARIsAGEX0zlwhVcfxo6fyfahYskfPkyvVAXhfcPbUGWsEGTmzSpn8wq1UerccjoaAsc_EALw_wcB">this one</a>. In this page I explain how I connected the phone and the Arduino to the GSM shield and what code I use for receviving and making a call.</p>
        <h2>Arduino to A6 connections</h2>
        <p>Connections between the Arduino and the A6 module are quite straight forward. I did have two issues that I didn't direclty debug:</p>
        <p><b>Power:</b> Connecting the Arduino to the laptop I use for code development and routing the 5V out of the Arduino to the A6 module does not allow for enough current draw for the module to fully function. Full functionality is acheived by powering the A6 module seperately. Later using the AC/DC module as described in the <Link to="../BellRinging">previous page</Link>, enough power is provided for full functionality.</p>
        <p><b>Communication:</b> In hindsight it sounds very stupid, but make sure you connect the Rx port ofthe Arduino to te Tx of the A6 and vice versa. We want to receive what one module is sending out by the other module.</p>
        <h2>A6 to phone connections</h2>
        <p>The only thing needed to connect to the A6 module is the horn of the phone, which contains to wires for the microphone and two for the speaker. Since the first plan was to connect the horn to the phone and the A6 module has a Jack input I bought a <a href="https://imgaz2.staticbg.com/thumb/large/oaupload/banggood/images/AF/B6/04aa6d8e-e4be-44df-ad1c-4b5d82414a5a.jpg">solderable converter</a>. Using the Jack plug and connecting it to the A6 only gave me the functionality of the microphone, not the speaker of the horn. After that I connected the horn directly to the pins on the A6 module and everything was fine.</p>
        <h2>Incoming call detection</h2>
        <p>A lot of examples out there, but I wanted a way to detect it with a simple command, which is why I went with te option below:
        <pre style={{maxHeight:"500px",overflow:"auto", backgroundColor:"#dedede",borderRadius:"1%"}}>
         <code>{` 
         //Rigistering the "RING" being transmitted by the SIM module == incoming call
         if (ringer.indexOf("RING") > 0) {
           Call_receive();
         }
         `}</code>
        </pre>
        where <i>ringer</i> is a String variable containing the most recent information available as received from the A6 module.
        </p>
        <h3>The SIM-card</h3>
        <p>Only one thing to say here, don't go to cheap on the SIM. I was happy like a child when I had and the A6 module and my SIM card, the latter bought after searching the web for the operator that give the the best rates without a monthly subscription. I went to bed crying that day. couldn't get it to receive cell connection, while most of the other communication was fine, could read the sim card number etc. I later tried with a SIM from a more prominent provider and everything worked instantly... <br /> Still haven't figured out why the other SIM didn't work.</p>
        <h2>The Code</h2>
        <p>
          The code used for the entire project can be found below. Feel free to reach out should anything be unclear. <br /> <br />
        <pre style={{maxHeight:"750px",overflow:"auto", backgroundColor:"#dedede",borderRadius:"1%"}}>
         <code>{` 
// Global variable definition
/// Varialbes mainly for receiving a call
int ringstate = 0;               // state of the bell
int hornstate = 0;               // is the telephone horn lifted or not
int hornstate_prev = 1;          // previous state of the hrn
String ringer = "";              // detecting an incomming call
char letter;

/// Variables mainly for making a call
float pulscount = 0;             // measured state of the 'digitalPin_cnt' signal
float pulscount_prev = 0;        // previous state of the 'digitalPin_cnt' signal
float reset = 0;                 // measured state of the 'digitalPin_reset' signal
float reset_prev = 0;            // previous state of the 'digitalPin_reset' signal
int count = 0;                   // variable counting the amount of pulses received when dialing a number
String PHnumber = "";            // string capturing the full phone number dialed
unsigned long timer;

// Arduino PIN selection
int digitalPin_cnt = 6;          // Pin 6 is input connected to the number pulses of rotary dial
int digitalPin_reset = 7;        // Pin 7 is input connected to the single pulse showing rotary dial usage
int digitalPin_ringer_out = 8;   // Pin 8 is output to the relay swithing the ringer on and off
int digitalPin_horn = 9;         // Pin 9 is input connected to the horn sensor detecting a picked up horn

void setup()
{
  pinMode(digitalPin_cnt, INPUT);
  pinMode(digitalPin_reset, INPUT);
  pinMode(digitalPin_ringer_out, OUTPUT);
  pinMode(digitalPin_horn, INPUT);

  //Begin serial communication with Arduino and Arduino IDE (Serial Monitor)
  Serial.begin(19200);
  //Begin serial communication with Arduino and A6
  Serial1.begin(19200);
  // Initializing the A6 module
  Serial.println("Initializing...");
  delay(1000);
  Serial1.println("AT+IPR?"); //checking the baud rate of the A6 module
  updateSerial();
  Serial1.println("AT+IPR=19200"); // setting the baud rate of the A6 module
  updateSerial();
  Serial1.println("AT"); //Once the handshake test is successful, it will back to OK
  updateSerial();
  Serial1.println("AT+CSQ"); //Signal quality test, value range is 0-31 , 31 is the best
  updateSerial();
  Serial1.println("AT+CCID"); //Read SIM information to confirm whether the SIM is plugged
  updateSerial();
  Serial1.println("AT+CREG?"); //Check whether it has registered in the network
  updateSerial();
  Serial1.println("AT"); //Once the handshake test is successful, it will back to OK
  updateSerial();
  Serial.println("Done!");
}

void loop()
{
  delay(10);
  hornstate_prev = hornstate;
  hornstate = digitalRead(digitalPin_horn);
  
//Detecting a picked up horn
  if (hornstate_prev == 1 && hornstate == 1)
  {
    Dialing();
  }
  
//Rigistering the "RING" being transmitted by the SIM module == incoming call
  if (ringer.indexOf("RING") > 0) {
    Call_receive();
  }
updateSerial(); 
}

// update the signals sent/received from/to the SIM module.
// don't write everything to the Serial.Println, causes problems and delays.
void updateSerial() 
{
  ringer = "";
  delay(250);
  while (Serial.available())
  {
    Serial1.write(Serial.read());//Forward what Serial received to Software Serial Port
  }
  while (Serial1.available())
  {
    letter = Serial1.read();
    ringer += letter;
  }
}

void Call_receive() // How to handle an incoming call
{
  boolean finishedcall = false;
  unsigned long timer;
  timer = micros();
  ringer = "";
  ringstate = 1;
  digitalWrite(digitalPin_ringer_out, ringstate);
  // while no 'ERROR' or '+..' is received indicating a stopped or cancelled call
  while (ringer.indexOf("O") < 0 && ringer.indexOf("+") < 0 && finishedcall == false) { 
    hornstate_prev = hornstate;
    hornstate = digitalRead(digitalPin_horn);
    // Turn on the horn of the phone with 1000 ms intervals
    if (micros() - timer > 1000000) 
    {  
      timer = micros();
      ringstate = (ringstate + 1) % 2;
      digitalWrite(digitalPin_ringer_out, ringstate);
    }
    // Detecting a picked up horn after incoming call
    if (hornstate == 1 && hornstate_prev == 0) 
    {
      digitalWrite(digitalPin_ringer_out, 0);
    // accept the call once the horn has been picked up
      Serial1.println("ATA");
      hornstate_prev = hornstate;
      while ((hornstate == 1 || hornstate_prev == 1)&& ringer.indexOf("+") < 0 && ringer.indexOf("O") < 0) { // 
        hornstate_prev = hornstate;
        hornstate = digitalRead(digitalPin_horn);
        delay(10);
        finishedcall = true;
      }
      Serial1.println("ATH");
      finishedcall = true;
      updateSerial();
      delay(10);
    }
    updateSerial();
    delay(10);
  }
  digitalWrite(digitalPin_ringer_out, 0);
  Serial1.println("AT");
  finishedcall = 1;
}
void Dialing() // Handling dialing and calling
{
  while (hornstate_prev == 1 && hornstate == 1) {
    delay(10);
    pulscount = digitalRead(digitalPin_cnt);
    reset = digitalRead(digitalPin_reset);
    hornstate_prev == hornstate;
    hornstate = digitalRead(digitalPin_horn);

    if ( pulscount == 0 && pulscount_prev == 1 && reset == 1) {
      count = count + 1;
      timer = micros();
    }
    if ( reset == 0 && reset_prev == 1) {
      PHnumber.concat(String(count % 10));
      count = 0;
      Serial.println(PHnumber);
      timer = micros();
    }
    if (micros() - timer >= 3000000 && PHnumber != "") {
      //Serial.println(PHnumber); // the dialed number.
      Serial1.println("ATD"+PHnumber); //call the dialed number
      PHnumber = "";
      timer = micros();
    }
    pulscount_prev = pulscount;
    reset_prev = reset;
  }
  Serial1.println("ATH");
}
         `}</code>
        </pre>
        </p>
      </div>
      <div className={pageLayout.side}>
        <h2 style={{}}>Project overview:</h2>
      <ProjectOverview></ProjectOverview>
      </div>
      </div>
    </div>
  )
}